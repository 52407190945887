import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, keywords }) => {
  const data = useStaticQuery(query)
  const { allContentfulSeo } = data

  const siteTitle = allContentfulSeo.nodes[0].title
  const siteDescription = allContentfulSeo.nodes[0].description
  const siteKeywords = allContentfulSeo.nodes[0].keywords
  const siteFavicon = allContentfulSeo.nodes[0].favicon.file.url
  const siteName = allContentfulSeo.nodes[0].siteName

  const seo = {
    title: title ? `${title} | ${siteName}` : `${siteTitle} | ${siteName}`,
    description: description || siteDescription,
    keywords: keywords || siteKeywords,
    favicon: siteFavicon,
  }

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />

      <meta name="keywords" content={seo.keywords.join(", ")} />

      <link rel="icon" type="image/png" sizes="32x32" href={seo.favicon} />

      <html lang="en" />

      <meta
        name="google-site-verification"
        content="OLS2BEun-5mxpEgYUpNpE0-2-zKdC2xjvZlxJ4X9i_k"
      />
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  favicon: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  keywords: null,
  favicon: null,
}

const query = graphql`
  query {
    allContentfulSeo(sort: { fields: createdAt }) {
      nodes {
        title
        description
        keywords
        favicon {
          file {
            url
          }
        }
        siteName
      }
    }
  }
`
